import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
{/*         <div
          className="col-lg-6 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="sm-title">
            <h4 className="font-alt">Formations</h4>
          </div>

          <ul className="aducation-box theme-bg">
            <li>
              <h6>DESS Informatique et Applications Scientifiques</h6>
               <span className="white-bg" style={{marginRight: 10}}>{`Mention TRES BIEN`}</span>
            </li>
            <li>
              <h6>Maîtrise d'ingénierie Mathématiques</h6>
               <span className="white-bg" style={{marginRight: 10}}>{`Mention AB`}</span>
            </li>
          </ul>
        </div> */}
        {/* End .col */}

        <div
          className="col-lg-8 my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="sm-title">
            <h4 className="font-alt">Expériences</h4>
          </div>
          <ul className="aducation-box dark-bg">

            <li>
              <span className="theme-bg">06/2022 - </span>
              <h6>CTO - Acelys Services Numériques</h6>
              <p>{`Nommé CTO afin de prendre en charge
               la direction technique et R&D.`}</p>
            </li>

            <li>
              <span className="theme-bg">10/2020-06/2022</span>
              <h6>Delivery Unit Director Development et membre du COMEX - Acelys Services Numériques</h6>
              <p>{`A la tête d'une équipe de 85 développeurs / scrum master / product owner / testeurs / chefs de projets,
              afin de gérer tous les projets nécessitant des développements spécifiques.`}</p>
              <span className="white-bg" style={{marginRight: 10}}>{`CA > 7,5 M€`}</span>
              <span className="white-bg" style={{marginRight: 10}}>{`85 ETP`}</span>
              <span className="white-bg" style={{marginRight: 10}}>{`> 25 clients`}</span>
            </li>
            <li>
              <span className="theme-bg">12/2018-10/2020</span>
              <h6>Directeur de Projet et Responsable du groupe Java - Acelys Services Numériques</h6>
              <p>{`DP sur un centre de service pour notre client Orange, et responsable hiérarchique de tous les collaborateurs
                du groupe Java`}</p>
              <span className="white-bg" style={{marginRight: 10}}>{`30-40 ETP`}</span>
            </li>
            <li>
              <span className="theme-bg">03/2015-12/2018</span>
              <h6>Engagement Manager - Capgemini Montpellier</h6>
              <p>{`Gestion de projets pour le groupe Crédit Agricole (CATS et CALF).`}</p>
            </li>
            <li>
              <span className="theme-bg">07/2007-03/2015</span>
              <h6>Développeur et Chef de Projet Technique - Capgemini Caen</h6>
              <p>{`Centre de Services Natixis Interepargne. Maintien en conditions opérationnelles du SI iRIS Gestion,
              permettant la gestion de l'épargne salariale au sein du groupe BPCE.`}</p>
              <span className="white-bg" style={{marginRight: 10}}>{`15 ETP`}</span>
            </li>
            <li>
              <span className="theme-bg">09/2005-07/2007</span>
              <h6>Développeur - Sogeti Paris</h6>
              <p>{`Développement informatique pour les groupes bancaires LCL et Crédit Agricole SA.`}</p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div
          className="col-lg-4 ml-auto my-3"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div className="sm-title">
            <h4 className="font-alt">Formation et Compétences</h4>
          </div>

          <ul className="aducation-box theme-bg"  style={{marginBottom: 30}}>
            <li>
              <h6>DESS Informatique et Applications Scientifiques</h6>
               <span className="white-bg" style={{marginRight: 10}}>{`Mention TRES BIEN`}</span>
            </li>
            <li>
              <h6>Maîtrise d'ingénierie Mathématiques</h6>
               <span className="white-bg" style={{marginRight: 10}}>{`Mention AB`}</span>
            </li>
          </ul>

          <div className="gray-bg skill-box">
            <div className="skill-lt">
              <h6>Veille technologique</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 100 + "%" }}
                >
                  <span>100%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
            <div className="skill-lt">
              <h6>Management de projets</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 98 + "%" }}
                >
                  <span>98%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
            <div className="skill-lt">
              <h6>Gestion d'équipe</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 95 + "%" }}
                >
                  <span>95%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
            <div className="skill-lt">
              <h6>Direction Technique</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 95 + "%" }}
                >
                  <span>95%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
            <div className="skill-lt">
              <h6>Développement mobile</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 90 + "%" }}
                >
                  <span>90%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
            <div className="skill-lt">
              <h6>Développement Web</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 90 + "%" }}
                >
                  <span>90%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>SGBD</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 90 + "%" }}
                >
                  <span>90%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>UI/UX</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 80 + "%" }}
                >
                  <span>80%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}

            <div className="skill-lt">
              <h6>Couteau suisse</h6>

              <div className="skill-bar">
                <div
                  className="skill-bar-in theme-bg"
                  style={{ width: 100 + "%" }}
                >
                  <span>100%</span>
                </div>
              </div>
            </div>
            {/* End skill-lt */}
          </div>
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
